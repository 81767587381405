<template>
  <b-card>
    <validation-observer ref="accountRules" tag="form">
      <b-form class="mt-1" @submit.prevent>
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">
              Data Instruktur
            </h5>
          </b-col>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="academic_year_id" rules="required">
              <b-form-group label="Tahun Akademik" label-for="academic_year_id" :state="errors.length > 0 ? false : null">
                <v-select id="academic_year_id" v-model="dataPrestasi.academic_year_id"
                  :reduce="(academic_year_id) => academic_year_id.id" placeholder="Pilih Tahun Akademik"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listTA" label="name" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider #default="{ errors }" name="extracurricular_type_id" rules="required">
              <b-form-group label="Tipe Ekstrakulikuler" label-for="extracurricular_type_id"
                :state="errors.length > 0 ? false : null">
                <v-select id="extracurricular_type_id" v-model="dataPrestasi.extracurricular_type_id"
                  :reduce="(extracurricular_type_id) => extracurricular_type_id.id"
                  placeholder="Pilih Tipe Ekstrakulikuler" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="listSiswa" label="type" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group label="Hari" label-for="event">
              <validation-provider #default="{ errors }" name="event" rules="required">
                <b-form-input id="event" v-model="dataPrestasi.event" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Jam" label-for="event">
              <validation-provider #default="{ errors }" name="event" rules="required">
                <b-form-input id="event" v-model="dataPrestasi.event" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Tipe Instruktur" label-for="event">
              <validation-provider #default="{ errors }" name="event" rules="required">
                <b-form-input id="event" v-model="dataPrestasi.event" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <!-- submit and reset -->
          <b-col offset-md="4">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
              @click="formSubmitted">
              Submit
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
              @click="() => $router.go(-1)">
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm, BButton,
} from 'bootstrap-vue'
import { required, email, size } from '@validations'
// import { codeIcon } from './code'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BForm,
    BButton,
  },
  data() {
    return {
      required,
      email,
      size,
      dataPrestasi: {
        student_id: '',
        date: '',
        event: '',
        category: '',
        achivement: '',
      },
      dataCategory: [
        { value: 1, text: 'Game' },
        { value: 2, text: 'Race' },
        { value: 3, text: 'Turnamen Category' },
      ],
      listSiswa: [],            
      listTA: [],
    }
  },
  created() {
    this.getSiswa(),
    this.getTA()
  },
  computed: {
  },
  methods: {
    async getSiswa() {
      try {
        const response = await this.$http.get('/masterextracurriculars')
        this.listSiswa = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getTA() {
      try {
        const response = await this.$http.get('/academicyears')
        this.listTA = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async formSubmitted() {
      try {
        const dataFormPreastasi = new FormData()
        Object.keys(this.dataPrestasi).forEach(key => {
          dataFormPreastasi.append(key, this.dataPrestasi[key])
        })
        await this.$http.post('/masterextracurriculars', dataFormPreastasi)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'data-ekstrakurikuler' })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Tidak Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        this.$router.push({ name: 'data-ekstrakurikuler' })
        console.log(err)
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
